@use 'variables' as v;

// FONTS

@font-face {
  font-family: 'Neue-Haas-Display';
  src: url('/assets/fonts/neue-haas-display/NeueHaasDisplayRoman.ttf')
    format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Mono';
  src: url('/assets/fonts/roboto-mono/RobotoMono-Medium.ttf') format('truetype');
}

// BASIC STYLES

body {
  background: v.$light-color;
  color: v.$dark-color;
}

p,
div {
  font-family: 'Neue-Haas-Display', sans-serif !important;
  letter-spacing: 1px;
  font-size: 14px;
}

h2,
h3 {
  font-family: 'Roboto-Mono', sans-serif !important;
  margin-bottom: 10px;
  margin-top: 10px;
  letter-spacing: 0px !important;
}

h1 {
  font-family: 'Neue-Haas-Display', sans-serif !important;
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 32px;
}

h2 {
  font-size: 28px !important;
}
h3 {
  font-size: 20px !important;
}

.ng-scroll-content {
  width: 100% !important;
}

// FORM STYLES

form {
  > div {
    margin-bottom: 15px !important;
  }
  p {
    font-weight: bold !important;
    margin-bottom: 8px !important;
  }
  label {
    font-weight: bold !important;
    margin-bottom: -5px !important;
  }
  input,
  textarea {
    margin-bottom: 2px !important;
  }
}

// BUTTON STYLES

p-button {
  .p-button {
    font-family: 'Neue-Haas-Display', sans-serif !important;
    letter-spacing: 1px;
    font-weight: bolder;
    display: flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 40px;
    max-height: 44px;
    width: fit-content;
    font-size: 14px;
    height: 40px;
    border-radius: 6px;
    background: v.$primary-color;
    border: 1px solid v.$primary-color;
    span.p-button-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: -3px;
    }
    span.p-button-icon {
      font-size: 12px !important;
      font-weight: bold !important;
    }
    &:not(:disabled):hover {
      background: v.$dark-color;
      border: 1px solid v.$dark-color;
    }
  }
  .p-button-outlined {
    font-family: 'Neue-Haas-Display', sans-serif !important;
    letter-spacing: 1px;
    font-weight: bold;
    display: flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 40px;
    max-height: 44px;
    width: fit-content;
    height: 40px;
    border-radius: 6px;
    border: 1px solid v.$primary-color;
    background: none;
    color: v.$primary-color;
    span.p-button-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: -3px;
    }
    span.p-button-icon {
      font-size: 12px !important;
      font-weight: bold !important;
    }

    &:not(:disabled):hover {
      background: none;
      color: v.$dark-color;
      border: 1px solid v.$dark-color;
    }
  }
  .p-button-text {
    font-family: 'Neue-Haas-Display', sans-serif !important;
    letter-spacing: 1px;
    font-weight: bold;
    display: flex;
    padding: 8px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 40px;
    max-height: 44px;
    width: fit-content;
    height: 40px;
    border: none;
    background: none;
    color: v.$primary-color;
    span.p-button-label {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: -3px;
    }
    span.p-button-icon {
      font-size: 12px !important;
      font-weight: bold !important;
    }

    &:not(:disabled):hover {
      background: none;
      color: v.$dark-color;
      border: none !important;
    }
  }
}
p-button.dark {
  .p-button-text {
    color: v.$dark-color;
    &:not(:disabled):hover {
      color: v.$primary-color;
    }
  }
  .p-button-outlined {
    color: v.$dark-color;
    border: 1px solid v.$dark-color;
    &:not(:disabled):hover {
      border: 1px solid v.$primary-color;
      color: v.$primary-color;
    }
  }
}
p-button.danger {
  .p-button {
    background: v.$danger-color !important;
    border: 1px solid v.$danger-color !important;
  }
  .p-button-text {
    color: v.$danger-color;
    &:not(:disabled):hover {
      color: v.$danger-color;
    }
  }
  .p-button-outlined {
    color: v.$danger-color;
    border: 1px solid v.$danger-color;
    &:not(:disabled):hover {
      border: 1px solid v.$danger-color;
      color: v.$danger-color;
    }
  }
}
p-button.w-100 {
  width: 100% !important;
  .p-button {
    width: 100% !important;
  }
}

// TABS STYLES

p-tabs {
  p-tab.p-tab-active {
    color: v.$primary-color !important;
    border-color: v.$primary-color !important;
  }
  .p-tablist-active-bar {
    background: v.$primary-color;
  }
  .p-tabpanels {
    background: none !important;
    padding: 0px !important;
  }
}

.p-tablist-tab-list {
  border-radius: 14px !important;
  overflow: hidden;
}

// DRAWER STYLES

.p-drawer {
  border-top-left-radius: 24px;
  background: v.$light-color !important;
}
.p-drawer-header {
  display: none !important;
}
.p-drawer.w-50 {
  width: 50% !important;
}
.p-drawer.w-25 {
  width: 25% !important;
}
.p-drawer.w-80 {
  width: 80% !important;
}

// BREADCRUMBS STYLES

p-breadcrumb {
  .p-breadcrumb {
    background: none;
    padding: 0;
    .p-breadcrumb-list {
      .p-breadcrumb-item {
        .p-breadcrumb-item-link {
          color: v.$primary-color;
          font-size: 14px;
        }
      }
    }
  }
}

// TABLE STYLES

p-table {
  .p-datatable {
    .p-datatable-header {
      background: none !important;
      padding: 0px !important;
      margin-bottom: 20px;
      border: none !important;
    }
    .p-datatable-table-container {
      min-height: 50vh;
      margin-bottom: 20px !important;
      background: white !important;
      border-radius: 14px !important;
      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }
      &::-webkit-scrollbar-track {
        background: white; /* Track background */
      }
      &::-webkit-scrollbar-thumb {
        background: v.$dark-color; /* Scrollbar color */
      }

      &::-webkit-scrollbar-thumb:hover {
        background: v.$dark-color; /* Hover color */
      }
    }
  }

  .p-datatable-filter {
    p-button {
      button {
        background: none !important;
      }
    }
  }

  .p-datatable-filter-overlay-popover {
    width: 250px !important;
  }
  .p-datatable-filter-buttonbar {
    display: none !important;
  }
  .p-datatable-filter-overlay-popover {
    display: none !important;
  }

  p-paginator {
    .p-paginator {
      background: none !important;
      p-select {
        width: fit-content !important;
      }
    }
    .p-paginator-page-selected {
      background: v.$primary-color !important;
      color: white !important;
    }
  }

  tbody {
    tr {
      font-size: 14px !important;
      &:hover {
        background: v.$light-color !important;
        border: 1px solid v.$primary-color !important;
      }
    }
  }

  thead {
    tr {
      th {
        div {
          font-size: 14px !important;
        }
        &:hover {
          background: v.$light-color !important;
        }
      }
    }
  }
}

// INPUT STYLES

p-inputgroup {
  p-inputgroupaddon {
    height: 40px !important;
    border: none !important;
    width: fit-content !important;
  }
  input {
    box-shadow: none !important;
    &::placeholder {
      font-family: 'Neue-Haas-Display', sans-serif !important;
      font-size: 14px !important;
      letter-spacing: 1px;
    }
    &:enabled {
      &:focus {
        border-color: v.$primary-color !important;
      }
    }
  }
}

input,
.input-class {
  color: v.$dark-color !important;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  border-color: white !important;
  font-family: 'Neue-Haas-Display', sans-serif !important;
  letter-spacing: 1px;
  &::placeholder {
    font-family: 'Neue-Haas-Display', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: 1px;
  }
  &:enabled {
    &:focus {
      border-color: v.$primary-color !important;
    }
  }
}

textarea {
  color: v.$dark-color !important;
  border-radius: 6px !important;
  font-size: 14px !important;
  border-color: white !important;
  font-family: 'Neue-Haas-Display', sans-serif !important;
  letter-spacing: 1px;
  &::placeholder {
    font-family: 'Neue-Haas-Display', sans-serif !important;
    font-size: 14px !important;
    letter-spacing: 1px;
  }
  &:enabled {
    &:focus {
      border-color: v.$primary-color !important;
    }
  }
}

p-iconfield {
  p-inputicon {
    i,
    span {
      font-size: 14px !important;
    }
  }
  input {
    padding-left: 35px !important;
  }
}

// SELECT & MULTI SELECT STYLES
#tableCaption {
  p-multiselect {
    border-color: v.$primary-color !important;
    background: none !important;
    .p-multiselect-dropdown {
      display: none !important;
    }
    .p-multiselect-label-container {
      .p-multiselect-label {
        padding: 8px 12px !important;
        border-radius: 6px !important;
        border-color: v.$primary-color;
        div {
          margin-top: 3px !important;
          i {
            color: v.$primary-color !important;
            font-size: 14px !important;
            font-weight: bold !important;
          }
        }
      }
    }
  }
}

.p-select-option.p-select-option-selected.p-focus,
.p-select-option.p-select-option-selected {
  background: v.$primary-color !important;
  color: white !important;
}

p-select {
  border-radius: 6px !important;
  border: none !important;
}

p-multiselect {
  box-shadow: none !important;
}

.p-select {
  border-radius: 6px !important;
  box-shadow: none !important;
  border: none !important;
  height: 40px !important;
}

.p-multiselect-label {
  padding-top: 12px !important;
  // font-size: smaller !important;
}

.p-multiselect-option {
  span {
    margin-bottom: -2px !important;
  }
  div {
    div {
      margin-bottom: -5px !important;
    }
  }
}

p-multiselect.filters {
  width: 150px !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  border-color: v.$primary-color !important;
}

.p-datatable-filter-overlay-popover {
  padding-bottom: 0 !important;
}

.p-treeselect-label {
  padding: 7px !important;
  .p-treeselect-chip-item {
    p-chip {
      background: v.$primary-color !important;
      color: white !important;
    }
  }
}

form {
  p-multiselect.p-multiselect {
    border-radius: 6px !important;
    box-shadow: none !important;
    border: none !important;
    height: 40px !important;
  }
  p-treeselect {
    .p-treeselect {
      border-radius: 6px !important;
      box-shadow: none !important;
      border: none !important;
      height: 40px !important;
      width: 100% !important;
    }
  }
}

// RADIO, CHECKBOX & TOGGLES STYLES

.p-checkbox-checked .p-checkbox-box {
  background: v.$primary-color !important;
  border-color: v.$primary-color !important;
}

.p-toggleswitch.p-toggleswitch-checked .p-toggleswitch-slider {
  background: v.$primary-color !important;
  border-color: v.$primary-color !important;
}

.p-radiobutton-checked .p-radiobutton-box {
  background: v.$primary-color !important;
  border-color: v.$primary-color !important;
}

// ACCORDION STYLES

p-accordion {
  p-accordion-panel {
    margin-bottom: 20px !important;
    border: none !important;
    p-accordion-header {
      padding: 14px 14px !important;
      font-size: 14px !important;
      border-radius: 14px !important;
    }
    p-accordion-content {
      margin-top: -10px !important;
      .p-accordioncontent-content {
        border-bottom-right-radius: 14px !important;
        border-bottom-left-radius: 14px !important;
        padding: 14px 14px !important;
      }
    }
  }
}

// SPEEDIAL STYLES

.p-speeddial {
  flex-direction: row !important;
  z-index: 9999;
}

// POPOVER STYLES

.p-popover:before,
.p-popover:after {
  content: unset !important;
}

// DIALOG STYLES

.p-dialog {
  width: fit-content !important;
  max-width: 50% !important;
}

// DATEPICKER

.p-datepicker-day-selected {
  background: v.$primary-color !important;
}

p-datepicker {
  span {
    input {
      box-shadow: none !important;
    }
  }
}

// QR PREVIEW STYLE

app-qr-preview {
  ngx-qrcode-styling {
    svg {
      width: 100% !important;
    }
  }
}

// ICON STYLES

.pi-angle-right:before {
  margin-left: 3px !important;
}

// STEPS STYLES

p-step.p-step-active {
  button {
    .p-step-title,
    .p-step-number {
      color: v.$primary-color !important;
    }
  }
}

p-stepper-separator {
  background: v.$primary-color !important;
}

// BADGE STYLES

.p-overlaybadge {
  width: fit-content !important;
  p-badge {
    background: v.$dark-color !important;
    color: white !important;
  }
}
